@import "../../../assets/scss/variable.scss";

.btn {
  &.custombtn {
    background: $BG_YELLOW;
    font-family: $NEXABOLD;
    border: 1px solid transparent !important;
    font-size: $FONT_18 !important;
    font-weight: normal;
    border-radius: 8px !important;
    padding: 6px 20px !important;
    color: $TEXT_CARBON !important;
    box-shadow: none;
    min-height: 48px;
    transition: none !important;
    cursor: pointer;


    &:disabled {
      opacity: 1 !important;
      cursor: no-drop;
      background: $BG_LIGHT_BLUE;
      pointer-events: all;
      color: $TEXT_DARKER_GRAY !important;
      border: 1px solid rgba(64, 87, 146, 0.4) !important;
    }

    &:not(:disabled) {
      box-shadow: none;
      background: $BG_YELLOW;
    }

    &:not(:disabled):not(.disabled):active {
      background: $BG_YELLOW;
    }

    &:not(.disabled):focus {
      box-shadow: none !important;
      background: $BG_YELLOW;
    }

    &.btn-loading {
      opacity: 1 !important;
      cursor: default;
    }
  }
}

@media screen and (min-width:768px) and (max-width:1024px) {
  .btn {
    &.custombtn {
      span {
        position: relative;
        // top: 2px;
      }
    }
  }
}