@import './assets/scss/variable.scss';

.App {
  position: relative;
  z-index: 9;
}

.showMores {
  .modal-dialog {
    max-width: 569px;
  }
}
// select2 css start
.css-1okebmr-indicatorSeparator {
  background: transparent !important;
}
.css-14el2xx-placeholder {
  font-size: 14px;
}
.css-tj5bde-Svg {
  color: #405792;
}
.modal-backdrop.show {
  opacity: 0;
}
// select2 css end
.tooltip {
  &.show {
    opacity: 1;
    transform: translate3d(734.545px, -214.545px, 0px);

    .votingDetail {
      display: flex;
      background-color: rgba(240, 244, 255, 1);
      border-radius: 8px;
      margin-bottom: 4px;

      &:last-child {
        margin: 0;
      }

      .blankConstituency {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: rgba(64, 87, 146, 0.4);
      }

      .authority {
        display: flex;
        flex-direction: column;
        width: calc(100% - 32px);
        padding-left: 4px;

        p {
          font-size: $FONT_12;
          font-family: $NEXAREGULAR;
          text-align: left;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.cName {
            color: $TEXT_LAMBENT_LAGOON;
          }

          &.vNumber {
            color: $TEXT_ORANGE;
          }
        }
      }
    }
  }

  .tooltip-inner {
    background-color: $BG_WHITE;
    padding: 8px 16px 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
      0px 20px 32px rgba(96, 97, 112, 0.24);

    .labelTitle {
      font-family: $NEXAREGULAR;
      color: $TEXT_DARKER_GRAY;
      font-size: $FONT_12;
      text-align: left;
    }
  }
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $BG_WHITE;
}

.votingTooltip {
  width: 187px;
  padding: 0;

  .tooltip-inner {
    padding: 7px 8px;
  }

  .tooltip-arrow {
    transform: translate3d(87.2727px, 7px, 0px) !important;
  }
}

.dropzoneCustom,
.uploadedImg,
.txtDropzone {
  &:focus-visible {
    outline: none;
  }
}

.calenderContainer {
  width: 48%;

  .react-datepicker__month-container {
    padding: 16px 16px;
    border: none;
    box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.12);
    border-radius: 8px;
  }

  .custom-date-input {
    background: $BG_WHITE;
    font-family: $NEXABOLD;
    color: $TEXT_LAMBENT_LAGOON;
    font-size: $FONT_12;
    padding: 14px 16px;
    padding-right: 40px;
    height: auto;
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(64, 87, 146, 0.4);
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: left;

    &::after {
      position: absolute;
      top: 8px;
      right: 16px;
      background-color: transparent;
      color: $TEXT_LAMBENT_LAGOON;
      height: 18px;
      width: 18px;
      padding: 0;
      font-size: $FONT_20;
      content: '\e90c';
      font-family: 'youngScot' !important;
    }
  }

  .custom-time-input {
    background: $BG_WHITE;
    font-family: $NEXABOLD;
    color: $TEXT_LAMBENT_LAGOON;
    font-size: $FONT_12;
    padding: 14px 16px;
    padding-right: 40px;
    height: auto;
    width: 100%;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: left;
    border: 1px solid rgba(64, 87, 146, 0.4);

    &::after {
      position: absolute;
      top: 8px;
      right: 16px;
      background-color: transparent;
      color: $TEXT_LAMBENT_LAGOON;
      height: 18px;
      width: 18px;
      padding: 0;
      font-size: $FONT_20;
      content: '\e910';
      font-family: 'youngScot' !important;
    }
  }

  .custom-date-placeholder {
    color: $TEXT_DARKER_GRAY;
    white-space: nowrap;
  }

  .react-datepicker__close-icon {
    padding: 0;
    margin-right: 12px;

    &::after {
      background-color: transparent;
      color: $TEXT_LAMBENT_LAGOON;
      height: 18px;
      width: 18px;
      padding: 0;
      font-size: $FONT_16;
      content: '\e910';
      font-family: 'youngScot' !important;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
    }
  }
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0 auto;
  overflow: auto;
  scrollbar-color: #e4e4e4 transparent;
  scrollbar-width: thin;
}

.table-scroll table {
  width: 100%;
  min-width: 800px;
  margin: auto;
  border-collapse: collapse;
  padding: 0;
}

.table-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.table-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.pastPollDetailPage .votingDetailTable.table-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.pastPollDetailPage .candidateVotingDetails .listing.table-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.pastPollDetailPage .votingDetailTable.table-scroll th{
  padding-left: 0px;
}
.table-scroll th {
  background-color: $BG_LIGHT_BLUE;
  // padding: 13px 16px;
  padding-left: 15px;
  padding-bottom: 17px;
  border-bottom: 1px solid #dbdeed;
  vertical-align: middle;
  font-family: $NEXABOLD;
  font-size: $FONT_14;
  color: $TEXT_LAMBENT_LAGOON;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: left;
  z-index: 9;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

// .table-scroll thead th .icon {
//   font-size: $FONT_16;
//   position: relative;
//   top: 3px;
//   cursor: pointer;
//   margin-left: 5px;
// }

.table-scroll tr td {
  
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 42px;
  }
  
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: left;
    padding-right: 0px;
  }
  &.noDataFound {
    text-align: center;
  }
}

.table-scroll tr td {
  background: $BG_WHITE;
  padding: 0;
  border: 0;
  vertical-align: middle;
  padding: 10px 16px;
  cursor: pointer;
  text-align: left;
  font-family: $NEXAREGULAR;
  font-size: $FONT_12;
  color: $TEXT_CARBON;
  // cursor: pointer;
  position: -webkit-sticky;
  position: sticky;
}

thead th {
  &:first-child {
    z-index: 99;
    // width: 12px;
  }
}

.table-wrap {
  position: relative;
}

.emailModal {
  .modal-dialog {
    max-width: 368px;

    .modal-content {
      padding: 16px;

      .innerEmailModal {
        .cityDetails {
          display: flex;
          padding: 8px;
          border: 1px solid rgba(64, 87, 146, 0.4);
          box-sizing: border-box;
          filter: drop-shadow(0px 0px 48px rgba(0, 0, 0, 0.04));
          border-radius: 8px;
          margin-bottom: 24px;

          .leftPart {
            margin-right: 10px;
            width: 45px;
            height: 45px;

            img {
              width: 100%;
              border: 1px solid $INPUT_BORDER;
              border-radius: 8px;
              height: 100%;
              object-fit: cover;
              overflow: hidden;
            }
          }

          .rightPart {
            p {
              margin: 0;

              &.cityName {
                font-family: $NEXABOLD;
                font-size: $FONT_20;
                color: $TEXT_LAMBENT_LAGOON;
                margin-bottom: 4px;
              }

              &.cityCode {
                font-family: $NEXAREGULAR;
                font-size: $FONT_14;
                color: $TEXT_CARBON;
              }
            }
          }
        }

        .emailAlter {
          color: $TEXT_LAMBENT_LAGOON;
          font-family: $NEXABOLD;
          font-size: $FONT_16;
          margin: 0;
          margin-bottom: 8px;
        }

        .emailDesc {
          font-family: $NEXAREGULAR;
          font-size: $FONT_14;
          color: $TEXT_DARKER_GRAY;
          margin-bottom: 8px;
        }

        form {
          .bottomButtons {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;

            .custombtn {
              width: 160px !important;

              &.cancel {
                background-color: $BG_WHITE !important;
                color: $TEXT_CARBON !important;
                border: 1px solid $INPUT_BORDER !important;
              }
            }
          }
        }
      }
    }
  }
}
.modal {
  background-color: #0000001c !important;
}

.addConstituency {
  overflow: hidden;

  .modal-dialog {
    width: 1004px;
    max-width: 96%;

    .modal-content {
      padding: 24px;
      box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      background: $BG_LIGHT_BLUE;

      .innerContainer {
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
        }

        .modalTitle {
          font-family: $NEXAREGULAR;
          font-size: $FONT_14;
          color: $TEXT_CARBON;
          margin-bottom: 16px;
        }

        .addConstituencyForm {
          display: flex;
          justify-content: space-between;

          .leftPart {
            margin-right: 16px;

            .dropzoneCustom {
              &:focus {
                outline: none;
              }
            }

            .constutuencyLogo {
              .uploadedImg {
                width: 108px;
                height: 108px;
                border: 1px dashed rgba(64, 87, 146, 0.4);
                box-sizing: border-box;
                border-radius: 8px;
                overflow: hidden;

                &:focus {
                  outline: none;
                }

                .uploadLogo {
                  background-color: $BG_WHITE;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  i {
                    color: $TEXT_LAMBENT_LAGOON;
                    font-size: $FONT_24;
                  }

                  .uploadLogotext {
                    padding-top: 8px;
                    white-space: pre-wrap;
                    text-align: center;
                    font-family: $NEXAREGULAR;
                    font-size: $FONT_10;
                    color: $TEXT_DARK_GRAY;
                    margin: 0;
                  }
                }

                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }

              .emptyLogo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 108px;
                height: 108px;
                border: 1px dashed rgba(64, 87, 146, 0.4);
                box-sizing: border-box;
                border-radius: 8px;
                overflow: hidden;

                i {
                  color: $TEXT_LAMBENT_LAGOON;
                  font-size: $FONT_24;
                }

                .uploadLogotext {
                  padding-top: 8px;
                  white-space: pre-wrap;
                  text-align: center;
                  font-family: $NEXAREGULAR;
                  font-size: $FONT_10;
                  color: $TEXT_DARK_GRAY;
                  margin: 0;
                }
              }
            }
          }

          .rightPart {
            width: calc(100% - 124px);

            .textField .iconSection {
              color: #ff4539;
            }

            .inlineTextFields {
              display: flex;

              .textField {
                margin-right: 10px;

                &:last-child {
                  margin-right: 0;
                }
              }

              .scotPhoneDiv {
                position: relative;
                width: 100%;

                .prefix {
                  position: absolute;
                  top: 14px;
                  left: 12px;
                  color: $TEXT_CARBON;
                  font-family: $NEXABOLD;
                  font-size: $FONT_14;
                  z-index: 99;
                }

                .textField .form-control {
                  padding-left: 44px !important;
                }
              }
            }
          }
        }

        .addConstituencyButton {
          float: right;
          margin-top: 8px;

          .addConstituency {
            color: $TEXT_CARBON;
            // background-color: transparent !important;
            font-size: $FONT_12 !important;
            // border-color: rgba(64, 87, 146, 0.4) !important;
          }

          .cancel {
            font-size: $FONT_12 !important;
            border-color: transparent !important;
            background-color: transparent !important;
            margin-right: 16px;
          }
        }

        .constituencyTitle {
          float: left;
          width: 100%;
          color: $TEXT_CARBON;
          font-family: $NEXAREGULAR;
          font-size: $FONT_14;
          margin: 24px 0;
        }

        .constituencyData {
          float: left;
          overflow: auto;
          white-space: nowrap;
          padding-bottom: 12px;
          display: flex;

          .constituencyBox {
            background-color: $BG_WHITE;
            border-radius: 8px;
            min-width: 314px;
            max-width: 314px;
            height: 226px;
            padding: 16px;
            margin-right: 8px;
            display: inline-block;

            &:last-child {
              margin-right: 0;
            }

            .innerBox {
              .innerData {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .emptyLogo {
                  width: 50px;
                  height: 50px;
                  background-color: $BG_LIGHT_BLUE;
                  margin-right: 8px;
                }

                .authorityLogo img {
                  width: 50px;
                  height: 50px;
                  object-fit: cover;
                  margin-right: 8px;
                }

                .inlineDataIcon {
                  display: flex;
                  justify-content: space-between;
                  width: calc(100% - 58px);

                  .authorityCity {
                    font-family: $NEXAREGULAR;
                    font-size: $FONT_14;
                    color: $TEXT_LAMBENT_LAGOON;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 60px);
                  }

                  .iconSection {
                    display: flex;
                    justify-content: space-between;

                    .icon {
                      font-size: $FONT_20;
                      cursor: pointer;
                    }

                    .icon-edit {
                      margin-right: 16px;
                      color: $LINK;
                    }
                  }
                }

                .emptyLine {
                  width: calc(100% - 58px);
                  height: 16px;
                  background-color: $BG_LIGHT_BLUE;
                }
              }

              .lowerData {
                width: 100%;

                .emptyRow {
                  background-color: $BG_LIGHT_BLUE;
                  height: 40px;
                  margin-top: 8px;
                }

                .rowData {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  background-color: $BG_LIGHT_BLUE;
                  height: 40px;
                  margin-top: 8px;
                  padding: 8px;
                  width: 100%;

                  i {
                    color: $TEXT_YELLOW;
                    font-size: $FONT_24;
                  }

                  .data {
                    margin-left: 8px;
                    color: $TEXT_CARBON;
                    font-size: $FONT_14;
                    font-family: $NEXAREGULAR;
                    width: calc(100% - 32px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }

        .footerButtons {
          float: right;
          margin-top: 24px;

          .discard {
            margin-right: 16px;
            background-color: transparent !important;
            border-color: transparent !important;
            color: $TEXT_DARKER_GRAY !important;
          }
        }
      }
    }
  }
}

.nec-number-list {
  .modal-dialog {
    .modal-content {
      background-color: $BG_CULTURED;
      width: auto;
      margin: 0 auto !important;

      .necModalMain {
        padding: 24px 4px;

        .necModalTitle {
          float: left;
          font-family: $NEXABOLD;
          font-size: $FONT_20;
          color: $TEXT_DARKER_GRAY;
          line-height: 22px;
          padding-right: 45px;
          padding-left: 16px;

          span {
            color: $TEXT_PINK;
          }

          &.small {
            width: 500px;
          }

          &.full {
            width: 100%;
          }
        }

        .fieldInline {
          // width: 100%;
          // float: left;

          .necModalLeft {
            width: 500px;
            float: left;
            padding: 0 4px 0 16px;
            .searchBoxDiv {
              padding-right: 12px;
            }

            .scrollbar {
              height: 455px !important;
              overflow-y: auto;
              -ms-overflow-style: none;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
              .listMain {
                padding-right: 12px;

                .list {
                  cursor: pointer;
                  margin-top: 8px;
                  padding: 14px;
                  background-color: $BG_WHITE;
                  border-radius: 8px;
                  border: 1px solid $INPUT_BORDER;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  span {
                    font-family: $NEXABOLD;
                    font-size: $FONT_18;
                    color: $TEXT_DARK_GRAY;
                  }

                  &.active {
                    border: 1px solid $TEXT_CARBON;

                    span {
                      color: $TEXT_CARBON;
                    }
                  }
                }

                .noData {
                  text-align: center;
                  margin-top: 8px;
                  padding: 14px;
                  background-color: $BG_WHITE;
                  border-radius: 8px;
                  border: 1px solid $INPUT_BORDER;

                  span {
                    font-family: $NEXABOLD;
                    font-size: $FONT_18;
                    color: $TEXT_DARK_GRAY;
                  }
                }
              }
            }
          }

          .necModalRight {
            width: 500px;
            float: left;
            border-left: 1px solid #dfe2e4;
            padding-left: 16px;
            padding-right: 16px;

            .scrollbar {
              height: 500px !important;
              overflow-y: auto;
              -ms-overflow-style: none;
              scrollbar-width: none;

              &::-webkit-scrollbar {
                display: none;
              }
              .subAuthorityListMain {
                padding-right: 10px;
                height: 100%;

                .emptyConstituency {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .labelTitle {
                    font-size: $FONT_20;
                    font-family: $NEXABOLD;
                    color: $TEXT_RED;
                  }
                }

                .arealist {
                  border-bottom: 1px solid #dfe2e4;
                  padding: 0 0 10px 0;
                  margin-bottom: 10px;
                  width: 100%;
                  float: left;

                  &:last-child {
                    border-bottom: 0;
                  }

                  h6 {
                    width: 100%;
                    float: left;
                    margin-bottom: 8px;
                    font-family: $NEXABOLD;
                    font-size: $FONT_14;
                    color: $TEXT_LAMBENT_LAGOON;
                  }

                  .arealistPart {
                    width: 100%;
                    float: left;

                    .areaLogo {
                      float: left;
                      width: 120px;
                      height: 70px;
                      border-radius: 8px;
                      overflow: hidden;
                      background-color: $INPUT_BORDER;

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                      }
                    }

                    .areaDetails {
                      padding-left: 10px;
                      float: left;
                      width: -moz-calc(100% - 120px);
                      width: -webkit-calc(100% - 120px);
                      width: -o-calc(100% - 120px);
                      width: calc(100% - 120px);

                      a {
                        margin: 0;
                        text-decoration: none;
                        font-family: $NEXAREGULAR;
                        font-size: $FONT_14;
                        color: $TEXT_DARKER_GRAY;

                        &:hover {
                          color: $TEXT_LAMBENT_LAGOON;
                        }
                      }

                      p {
                        margin: 0 0 3px 0;
                        font-family: $NEXAREGULAR;
                        font-size: $FONT_14;
                        color: $TEXT_DARKER_GRAY;
                      }

                      span {
                        display: block;
                        font-family: $NEXAREGULAR;
                        font-size: $FONT_14;
                        color: $TEXT_DARKER_GRAY;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bottomButton {
          width: 100%;
          float: left;
          padding-left: 16px;
          padding-right: 16px;
          .necModalTitleSecond {
            font-family: $NEXABOLD;
            font-size: $FONT_20;
            color: $TEXT_DARKER_GRAY;
            line-height: 22px;
            padding-right: 20px;
            margin: 16px 0;

            span {
              color: $TEXT_PINK;
            }
          }

          .gotit {
            min-width: 140px;
          }
        }
      }
    }
  }
}

.showMore {
  overflow: hidden;

  .modal-dialog {
    width: 1004px;
    max-width: 96%;

    .modal-content {
      .innerContainer {
        padding: 8px;
        display: flex;

        .leftPart {
          height: 48px;
          width: 48px;
          margin-right: 8px;

          img {
            height: 48px;
            width: 48px;
            object-fit: cover;
            border-radius: 24px;
          }
        }

        .rightPart {
          width: calc(100% - 56px);

          .candidateName {
            font-family: $NEXAREGULAR;
            font-size: $FONT_14;
            color: $TEXT_LAMBENT_LAGOON;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 4px;
          }

          .candidateConstituency {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            padding: 4.5px 8px;
            background: rgba(251, 207, 63, 0.2);
            border: 1px solid #fbcf3f;
            border-radius: 50px;
            font-size: $FONT_10;
            font-family: $NEXAREGULAR;
            color: $TEXT_CARBON;
            max-width: 100%;
            margin-bottom: 4px;
          }

          .description {
            font-size: $FONT_12;
            font-family: $NEXAREGULAR;
            color: $TEXT_DARKER_GRAY;
            word-break: break-all;
          }
        }
      }
    }
  }
}

.backdrop {
  background-color: transparent;

  .show {
    opacity: 1;
  }
}

.offcanvas {
  margin-top: 66px;
  padding: 16px;
  width: 375px;
  background-color: $BG_WHITE;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
  border-left: 0;
  height: -moz-calc(100% - 66px);
  height: -webkit-calc(100% - 66px);
  height: -o-calc(100% - 66px);
  height: calc(100% - 66px);

  .icon-cross {
    cursor: pointer;
    font-size: $FONT_22;
    color: $TEXT_CARBON;
  }

  .profileTitleText {
    margin: 10px 0 0 0;
    font-family: $NEXABOLD;
    font-size: $FONT_18;
    color: $TEXT_LAMBENT_LAGOON;
  }

  .alignCenter {
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: row;
    display: grid;

    .dummyProfile {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      display: block;
    }

    .editProfile {
      width: 300px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 8px;
      padding: 16px;
      background-color: $BG_WHITE;
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
      margin-bottom: 8px;

      .labelTitle {
        font-family: $NEXABOLD;
        font-size: $FONT_16;
        color: $TEXT_LAMBENT_LAGOON;
        margin: 0 0 8px 0;
      }

      .buttonInline {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;

        .custombtn {
          width: 48%;
          font-size: $FONT_16 !important;
        }

        .cancel {
          border: 1px solid $BUTTONBORDER !important;
          background-color: transparent;

          &:focus {
            background-color: transparent !important;
          }
        }
      }

      .profileRound {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background-color: $BG_LAMBENT_LAGOON;
        pointer-events: none;

        img {
          width: 80px;
          height: 80px;
          border-radius: 40px;
          object-fit: cover;
        }

        p {
          margin: 0;
          font-family: $NEXABOLD;
          font-size: $FONT_20;
          color: $TEXT_WHITE;
          text-transform: uppercase;
        }
      }

      .dropzoneCustom {
        position: relative;
        margin: 0;
        width: 80px;
        margin-bottom: 24px;

        .editText {
          font-family: $NEXAREGULAR;
          font-size: $FONT_16;
          color: $LINK;
          display: block;
          text-align: center;
        }
      }
    }

    .profileSection {
      margin-top: 45px;
      margin-bottom: 80px;

      .userButtonImage {
        display: flex;
        align-items: center;
        justify-content: center;

        .userAvtar {
          width: 80px;
          height: 80px;
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $BG_LAMBENT_LAGOON;

          p {
            margin: 0;
            font-family: $NEXABOLD;
            font-size: $FONT_20;
            color: $TEXT_WHITE;
            text-transform: uppercase;
          }

          img {
            width: 80px;
            height: 80px;
            border-radius: 40px;
            object-fit: cover;
          }
        }
      }

      .nameWithDetails {
        margin-top: 18px;
        text-align: center;
        font-family: $NEXAREGULAR;

        h6 {
          margin: 0;
          font-size: $FONT_14;
          color: $TEXT_DARKER_GRAY;
        }

        p {
          margin: 6px 0;
          font-size: $FONT_14;
          color: $TEXT_DARKER_GRAY;
        }

        span {
          color: $TEXT_LAMBENT_LAGOON;
          font-size: $FONT_16;
          display: inline-block;
          cursor: pointer;
        }
      }
    }

    .logOutcenter {
      margin: 16px 0 0 0;
      text-align: center;

      &.disabled {
        span {
          pointer-events: none;
          // cursor: not-allowed;
          opacity: 0.5;
        }
      }

      span {
        font-family: $NEXAREGULAR;
        font-size: $FONT_18;
        color: $TEXT_RED;
        cursor: pointer;
      }
    }

    .changePassword {
      width: 300px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 8px;
      padding: 16px;
      background-color: $BG_WHITE;
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
      margin-bottom: 8px;

      .labelTitle {
        font-family: $NEXABOLD;
        font-size: $FONT_16;
        color: $TEXT_LAMBENT_LAGOON;
        margin: 0 0 8px 0;
      }

      .textField {
        &:nth-child(3) {
          margin-bottom: 16px;
        }
      }

      .buttonInline {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;

        .custombtn {
          width: 48%;
          font-size: $FONT_16 !important;
        }

        .cancel {
          border: 1px solid $BUTTONBORDER !important;
          background-color: transparent;

          &:focus {
            background-color: transparent !important;
          }
        }
      }
    }

    .changePasswordText {
      margin: 0;
      text-align: center;

      span {
        font-family: $NEXAREGULAR;
        font-size: $FONT_18;
        color: $TEXT_CARBON;
        cursor: pointer;
      }
    }
  }
}

.confirm-logout {
  .icon-alert-octagon {
    color: $TEXT_YELLOW;
  }
}

.roundDetailModal {
  overflow: hidden;

  .modal-dialog {
    max-width: 442px;
  }

  .roundDetailInnerContainer {
    padding: 16px;
    background-color: $BG_LIGHT_BLUE;

    .inlineDataIcon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .modalTitle {
        margin: 0;
        font-family: $NEXABOLD;
        font-size: $FONT_14;
        line-height: 14px;
        color: $TEXT_CARBON;
        padding-bottom: 10px;

        .orangeText {
          color: $TEXT_ORANGE;
        }
      }

      i {
        position: relative;
        top: 0;
        right: 0;
        font-size: $FONT_14;
      }
    }

    .roundDetails {
      overflow: auto;
      padding-right: 8px;

      .roundNumber {
        font-size: $FONT_14;
        font-family: $NEXABOLD;
        line-height: 100%;
        color: $TEXT_LAMBENT_LAGOON;
        padding: 17px 15px;
        border-bottom: 1px solid rgba(34, 23, 71, 0.1);
      }
    }

    .innerDetailContainer {
      padding: 8px 0;

      .candidateDetail {
        padding: 12px 4px;
        background-color: $BG_WHITE;
        margin-bottom: 8px;
        border-radius: 8px;

        position: relative;

        &.winner {
          background-color: $BG_LAMBENT_LAGOON;

          .inner {
            .profileName {
              .cName {
                color: $TEXT_WHITE;
              }
            }

            .votingDetails {
              background-color: $BG_YELLOW;
            }
          }
        }

        &.eliminated {
          .inner {
            .profileName {
              .cName {
                color: $TEXT_RED;
              }
            }
          }
          &::after {
            position: absolute;
            width: 100%;
            height: 1px;
            background: rgba(64, 87, 146, 0.4);
            z-index: 1;
            display: block;
            top: 50%;
            content: '';
            left: 0;
          }
        }

        .inner {
          width: 67%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .profileName {
            display: flex;
            align-items: center;

            &:last-child {
              margin: 0;
            }

            .profileImage {
              width: 40px;
              height: 40px;
              border-radius: 20px;
              object-fit: cover;
            }

            .cName {
              color: $TEXT_CARBON;
              font-size: $FONT_12;
              font-family: $NEXAREGULAR;
              padding-left: 10px;
            }
          }

          .votingDetails {
            display: flex;
            align-items: center;
            border: 1px solid rgba(64, 87, 146, 0.4);
            border-radius: 30px;
            background-color: $BG_LIGHT_BLUE;
            width: max-content;
            padding: 7px 8px 7px 9.5px;

            img {
              width: 19px;
              height: 24px;
            }

            .nVotes {
              padding-left: 6px;
              font-family: $NEXAREGULAR;
              font-size: $FONT_12;
              position: relative;
              top: 2px;
            }
          }
        }

        .Winner {
          position: absolute;
          top: 18px;
          right: 8px;

          img {
            width: 24px;
            height: 100%;
          }
        }
      }
    }
  }
}

// .confirm-remove {
//   .icon-alert-octagon {
//     color: $TEXT_RED;
//   }
// }
.delete-data-modal {
  .icon-alert-octagon {
    color: $TEXT_RED;
  }
}

.nec-number-list {
  .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.winnerModal {
  .modal-dialog {
    width: 100%;
    max-width: 644px;

    .modal-content {
      padding: 10px;

      .icon-cross {
        right: 12px;
        top: 12px;
      }

      .noData {
        text-align: center;
        margin-top: 8px;
        padding: 14px;
        background-color: $BG_WHITE;

        span {
          font-family: $NEXABOLD;
          font-size: $FONT_18;
          color: $TEXT_DARK_GRAY;
        }
      }

      .announce_result {
        margin: 0;
        font-family: $NEXABOLD;
        font-size: $FONT_20;
        color: $TEXT_DARKER_GRAY;
      }

      .fptplist {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        overflow-y: auto;
        height: 400px;

        /* width */
        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #f8f8f8;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #f8f8f8;
        }

        li {
          list-style: none;
          margin-top: 8px;
          padding: 10px;
          border-radius: 8px;
          background-color: $BG_LIGHT_BLUE;
          width: 100%;
          float: left;

          &.selected {
            background-color: $BG_LAMBENT_LAGOON;
          }

          .candidateProfile {
            width: 54px;
            height: 54px;
            border-radius: 27px;
            float: left;

            img {
              width: 100%;
              height: auto;
              display: block;
              object-fit: contain;
            }
          }

          .percentagePart {
            padding-left: 16px;
            float: left;
            // width: -moz-calc(100% - 104px);
            // -webkit-width: -webkit-calc(100% - 104px);
            // -moz-width: -o-calc(100% - 104px);
            // -ms-width: calc(100% - 104px);

            p {
              margin: 0;
              font-family: $NEXAREGULAR;
              font-size: $FONT_14;
            }

            .inline {
              position: relative;
              width: 400px;

              .percentage {
                height: 30px;
                border-radius: 8px;
              }

              .vote {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 7px;
                height: 34px;
                border-radius: 30px;
                position: absolute;
                right: 0;
                top: -2px;
                margin-right: -72px;

                i {
                  font-size: $FONT_18;
                  margin-right: 8px;
                }

                p {
                  margin: 0;
                  font-family: $NEXAREGULAR;
                  font-size: $FONT_12;
                  color: $TEXT_LAMBENT_LAGOON;
                  position: relative;
                  top: 1.5px;
                }
              }
            }
          }

          .winner {
            width: 50px;
            float: right;
            margin-top: 23px;
            text-align: center;

            img {
              max-width: 100%;
              height: auto;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.winnerModalSvt {
  .modal-dialog {
    width: 100%;
    max-width: 940px;

    .modal-content {
      padding: 10px;

      .icon-cross {
        right: 12px;
        top: 12px;
      }

      .noData {
        text-align: center;
        margin-top: 8px;
        padding: 14px;
        background-color: $BG_WHITE;

        span {
          font-family: $NEXABOLD;
          font-size: $FONT_18;
          color: $TEXT_DARK_GRAY;
        }
      }

      .announce_result {
        margin: 0;
        font-family: $NEXABOLD;
        font-size: $FONT_20;
        color: $TEXT_DARKER_GRAY;
      }

      .roundButtons {
        overflow: auto;
        white-space: nowrap;

        /* width */
        &::-webkit-scrollbar {
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #f8f8f8;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #f8f8f8;
        }

        .tab {
          display: inline-block;
          font-family: $NEXABOLD;
          font-size: $FONT_14;
          color: $PHILIPPINE_GRAY;
          padding: 15px 0;
          margin: 0 20px;
          cursor: pointer;

          &.active {
            color: $TEXT_LAMBENT_LAGOON;
          }
        }
      }

      .svtMain {
        width: 100%;
        float: left;

        .svtLeft {
          width: 68%;
          float: left;

          .svtlist {
            margin: 0;
            padding: 0;
            width: 100%;
            float: left;
            overflow-y: auto;
            height: 400px;

            /* width */
            &::-webkit-scrollbar {
              width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #f8f8f8;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #f8f8f8;
            }

            li {
              list-style: none;
              margin-bottom: 8px;
              padding: 10px;
              border-radius: 8px;
              background-color: $BG_LIGHT_BLUE;
              width: 100%;
              float: left;

              .candidateProfile {
                width: 54px;
                height: 54px;
                border-radius: 27px;
                float: left;

                img {
                  width: 100%;
                  height: auto;
                  display: block;
                  object-fit: contain;
                }
              }

              .percentagePart {
                padding-left: 16px;
                float: left;
                width: -moz-calc(100% - 54px);
                width: -webkit-calc(100% - 54px);
                width: -o-calc(100% - 54px);
                width: calc(100% - 54px);

                p {
                  margin: 0;
                  font-family: $NEXAREGULAR;
                  font-size: $FONT_14;
                  color: $TEXT_LAMBENT_LAGOON;
                }

                .percentage {
                  float: left;
                  height: 30px;
                  border-radius: 8px;
                  background-color: $HOVER_BORDER;
                }
              }
            }
          }
        }

        .svtRight {
          width: 31%;
          float: right;
          background-color: $BG_LIGHT_BLUE;
          padding: 8px;
          border-radius: 8px;

          .parti_canidated {
            margin: 0;
            font-family: $NEXABOLD;
            font-size: $FONT_14;
            color: $TEXT_CARBON;
          }

          .elected_canidated {
            margin: 0;
            font-family: $NEXABOLD;
            font-size: $FONT_14;
            color: $TEXT_ORANGE;
          }

          .winnerlist {
            margin: 0;
            padding: 0;
            width: 100%;
            float: left;
            overflow-y: auto;
            height: 340px;

            /* width */
            &::-webkit-scrollbar {
              width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #f8f8f8;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #f8f8f8;
            }

            li {
              list-style: none;
              padding: 12px 0;
              border-bottom: 1px solid $BORDER;
              width: 100%;
              float: left;
              display: flex;
              align-items: center;

              &:last-child {
                border-bottom: 0;
              }

              img {
                width: 45px;
                height: 45px;
                border-radius: 22.5px;
                display: block;
                object-fit: contain;
              }

              .percentagePart {
                padding-left: 10px;
                float: left;
                width: -moz-calc(100% - 45px);
                width: -webkit-calc(100% - 45px);
                width: -o-calc(100% - 45px);
                width: calc(100% - 45px);

                p {
                  margin: 0;
                  font-family: $NEXAREGULAR;
                  font-size: $FONT_14;
                  color: $TEXT_LAMBENT_LAGOON;
                }
              }

              .winnerPart {
                text-align: center;
                min-width: 75px;

                p {
                  text-align: center;
                  margin: 5px 0 0 0;
                  font-family: $NEXAREGULAR;
                  font-size: $FONT_12;
                  color: $TEXT_LAMBENT_LAGOON;
                }

                img {
                  margin: 0 auto;
                  width: 30px;
                  height: auto;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .nec-number-list {
    z-index: 1055;
    overflow-y: hidden !important;
    .modal-dialog {
      width: 96% !important;
      margin: 0 auto;
      min-height: 100%;
      .modal-content {
        .icon-cross {
          right: 10px;
          top: 14px;
        }

        .necModalMain {
          padding: 8px 0;

          .necModalTitle {
            font-size: $FONT_14;
            line-height: 18px;
            padding-right: 30px;
            padding-left: 12px;
            margin-bottom: 10px;
            &.small {
              width: 100%;
            }

            &.full {
              width: 100%;
            }
          }

          .fieldInline {
            width: 100%;
            float: left;
            position: static;
            // overflow-y: scroll;
            // height: calc(100vh - 140px);
            &.listDetails {
              height: calc(100vh - 230px);
            }
            .necModalLeft {
              width: 100%;
              padding: 0 4px;
              .searchBoxDiv {
                padding-right: 0;
                position: absolute;
                top: -48px;
                width: calc(100% - 20px);
                left: 10px;
              }

              .scrollbar {
                height: 400px !important;
                overflow-y: scroll;

                .listMain {
                  padding-right: 8px;
                  padding-left: 8px;
                  .list {
                    padding: 10px;

                    span {
                      font-size: $FONT_14;
                    }
                  }

                  .noData {
                    padding: 12px;

                    span {
                      font-size: $FONT_14;
                    }
                  }
                }
              }
            }

            .necModalRight {
              width: 100%;
              padding-left: 0px;
              padding-right: 0px;
              margin-top: 0px;
              border: 0;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 99;
              background-color: #f8f8f8;
              padding-top: 30px;
              .rightClose{
                top: 10px;
                right: 8px;
              }
              .scrollbar {
                height: 340px !important;
                overflow-y: hidden;
                .subAuthorityListMain {
                  padding-left: 12px;
                  padding-right: 12px;
                  .arealist {
                    .arealistPart {
                      .areaLogo {
                        width: 75px;
                        height: 70px;
                      }

                      .areaDetails {
                        padding-left: 8px;
                        width: -moz-calc(100% - 75px);
                        width: -webkit-calc(100% - 75px);
                        width: -o-calc(100% - 75px);
                        width: calc(100% - 75px);
                      }
                    }
                  }
                }
              }
            }
          }

          .bottomButton {
            padding-left: 12px;
            padding-right: 12px;
            position: absolute;
            bottom:8px;
            background-color: #f8f8f8;
            .necModalTitleSecond {
              font-size: $FONT_14;
              line-height: 16px;
              margin: 10px 0;
            }

            .gotit {
              min-width: 100px;
              min-height: 38px !important;
              font-size: $FONT_14 !important;
            }
          }
        }
      }
    }
  }

  .winnerModal {
    .modal-dialog {
      width: auto;

      .modal-content {
        .fptplist {
          li {
            .winner {
              margin-top: 0;

              img {
                width: 30px;
                margin: 0;
                float: right;
              }
            }

            .percentagePart {
              .inline {
                width: 130px;

                .vote {
                  margin-top: 8px;
                  width: max-content;
                  position: initial;
                }
              }
            }
          }
        }
      }
    }
  }

  .winnerModalSvt {
    .modal-dialog {
      width: auto;

      .modal-content {
        .svtMain {
          .svtLeft {
            width: 100%;
            margin-bottom: 8px;

            .svtlist {
              height: 230px;
            }
          }

          .svtRight {
            width: 100%;

            .winnerlist {
              height: 270px;
            }
          }
        }
      }
    }
  }
}

.howToVote {
  overflow: hidden;

  .modal-dialog {
    max-width: 96%;
    width: 1000px;

    .modal-content {
      display: block;

      .icon-cross {
        position: relative;
        float: right;
        top: 0;
        right: 0;
        margin: 14px;
      }

      .step-back {
        background-color: $BG_CULTURED;
        width: 100%;
        float: left;
        position: relative;
        border-radius: 8px;
        padding: 16px;
        overflow: auto;

        .title {
          text-align: center;
          margin: 20px 0 20px 0;
          font-family: $NEXABOLD;
          font-size: $FONT_16;
          color: $TEXT_LAMBENT_LAGOON;
        }

        img {
          margin-top: 14px;
          margin-bottom: 14px;
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
          width: 250px;
          height: auto;
          display: block;
        }

        ul {
          margin: 0 auto;
          padding: 0px;

          li {
            list-style: none;
            position: relative;
            padding-left: 20px;
            font-size: $FONT_14;
            color: $TEXT_DARKER_GRAY;
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }

            &::before {
              position: absolute;
              content: '';
              left: 0;
              top: 6px;
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background-color: $BG_LAMBENT_LAGOON;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .modal-dialog {
    padding: 0 16px;
  }

  .addConstituency {
    &.show {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 0;

      .modal-dialog {
        margin: 0;
      }
    }
  }

  .nec-number-list {
    .modal-dialog {
      max-width: inherit;

      .modal-content {
        .icon-cross {
          right: 14px;
          top: 14px;
        }

        .necModalMain {
          padding: 14px 0 14px 14px;

          .necModalTitle {
            font-size: $FONT_14;
            line-height: 16px;
            padding-right: 20px;

            &.small {
              width: 312px;
              padding-right: 20px;
            }

            &.full {
              width: 100%;
            }
          }

          .fieldInline {
            .necModalLeft {
              width: 340px;

              .scrollbar {
                height: 400px !important;

                .listMain {
                  .list {
                    padding: 14px;

                    span {
                      font-size: $FONT_12;
                    }
                  }

                  .noData {
                    span {
                      font-size: $FONT_12;
                    }
                  }
                }
              }
            }

            .necModalRight {
              width: 340px;

              .scrollbar {
                height: 450px !important;

                .subAuthorityListMain {
                  .arealist {
                    .arealistPart {
                      .areaLogo {
                        float: left;
                        width: 75px;
                        height: 70px;
                      }

                      .areaDetails {
                        padding-left: 9px;
                        width: -moz-calc(100% - 75px);
                        width: -webkit-calc(100% - 75px);
                        width: -o-calc(100% - 75px);
                        width: calc(100% - 75px);

                        p,
                        a,
                        span {
                          font-size: $FONT_12;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .bottomButton {
            .necModalTitleSecond {
              font-size: $FONT_18;
            }

            .gotit {
              min-width: 130px;
            }
          }
        }
      }
    }
  }

  .howToVote {
    .modal-dialog {
      .modal-content {
        .step-back {
          .title {
            font-size: $FONT_16;
          }

          ul {
            li {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .nec-number-list {
    .modal-dialog {
      .modal-content {
        .icon-cross {
          right: 14px;
          top: 14px;
        }

        .necModalMain {
          padding: 14px 0 14px 14px;

          .necModalTitle {
            font-size: $FONT_16;
            line-height: 16px;
            padding-right: 30px;

            &.small {
              width: 355px;
              padding-right: 30px;
            }

            &.full {
              width: 100%;
            }
          }

          .fieldInline {
            .necModalLeft {
              width: 378px;

              .scrollbar {
                height: 400px !important;

                .listMain {
                  .list {
                    padding: 12px;

                    span {
                      font-size: $FONT_14;
                    }
                  }

                  .noData {
                    span {
                      font-size: $FONT_14;
                    }
                  }
                }
              }
            }

            .necModalRight {
              width: 378px;

              .scrollbar {
                height: 450px !important;

                .subAuthorityListMain {
                  .arealist {
                    .arealistPart {
                      .areaLogo {
                        float: left;
                        width: 75px;
                        height: 70px;
                      }

                      .areaDetails {
                        padding-left: 9px;
                        width: -moz-calc(100% - 75px);
                        width: -webkit-calc(100% - 75px);
                        width: -o-calc(100% - 75px);
                        width: calc(100% - 75px);
                      }
                    }
                  }
                }
              }
            }
          }

          .bottomButton {
            .necModalTitleSecond {
              font-size: $FONT_16;
            }

            .gotit {
              min-width: 130px;
            }
          }
        }
      }
    }
  }

  .howToVote {
    .modal-dialog {
      .modal-content {
        .step-back {
          .title {
            font-size: $FONT_16;
          }

          ul {
            li {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .nec-number-list {
    .modal-dialog {
      .modal-content {
        .necModalMain {
          // padding: 8px;

          .necModalTitle {
            font-size: $FONT_14;
          }

          .fieldInline {
            .necModalLeft {
              .searchBoxDiv {
                .searchBox {
                  .searchInput {
                    font-size: $FONT_12;

                    &::placeholder {
                      font-size: $FONT_12;
                    }
                  }
                }
              }

              .scrollbar {
                .listMain {
                  .list {
                    span {
                      font-size: $FONT_12;
                    }
                  }
                }
              }
            }

            .necModalRight {
              .scrollbar {
                .subAuthorityListMain {
                  .arealist {
                    .arealistPart {
                      .areaLogo {
                        width: 74px;
                      }

                      .areaDetails {
                        p {
                          font-size: $FONT_12 !important;
                        }

                        span {
                          font-size: $FONT_12 !important;
                        }

                        a {
                          font-size: $FONT_12 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .bottomButton {
            .necModalTitleSecond {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }

  .howToVote {
    .modal-dialog {
      .modal-content {
        .step-back {
          .title {
            font-size: $FONT_16;
          }

          ul {
            li {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }

  .addConstituency {
    &.show {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 0;

      .modal-dialog {
        margin: 0;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  .nec-number-list {
    .modal-dialog {
      .modal-content {
        .necModalMain {
          // padding: 8px;

          .necModalTitle {
            font-size: $FONT_14;

            &.small {
              width: 380px;
            }
          }

          .fieldInline {
            .necModalLeft {
              width: 380px;

              .searchBoxDiv {
                .searchBox {
                  .searchInput {
                    font-size: $FONT_12;

                    &::placeholder {
                      font-size: $FONT_12;
                    }
                  }
                }
              }

              .scrollbar {
                .listMain {
                  .list {
                    span {
                      font-size: $FONT_12;
                    }
                  }
                }
              }
            }

            .necModalRight {
              width: 380px;

              .scrollbar {
                .subAuthorityListMain {
                  .arealist {
                    .arealistPart {
                      .areaLogo {
                        width: 74px;
                      }

                      .areaDetails {
                        p {
                          font-size: $FONT_12 !important;
                        }

                        span {
                          font-size: $FONT_12 !important;
                        }

                        a {
                          font-size: $FONT_12 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .bottomButton {
            .necModalTitleSecond {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }

  .howToVote {
    .modal-dialog {
      .modal-content {
        .step-back {
          .title {
            font-size: $FONT_16;
          }

          ul {
            li {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }

  .addConstituency {
    &.show {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 0;

      .modal-dialog {
        margin: 0;
      }
    }
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .nec-number-list {
    .modal-dialog {
      .modal-content {
        .necModalMain {
          padding: 8px;

          .necModalTitle {
            font-size: $FONT_14;

            &.small {
              width: 380px;
            }
          }

          .fieldInline {
            .necModalLeft {
              width: 380px;

              .searchBoxDiv {
                .searchBox {
                  .searchInput {
                    font-size: $FONT_12;

                    &::placeholder {
                      font-size: $FONT_12;
                    }
                  }
                }
              }

              .scrollbar {
                .listMain {
                  .list {
                    span {
                      font-size: $FONT_12;
                    }
                  }
                }
              }
            }

            .necModalRight {
              width: 380px;

              .scrollbar {
                .subAuthorityListMain {
                  .arealist {
                    .arealistPart {
                      .areaLogo {
                        width: 74px;
                      }

                      .areaDetails {
                        p {
                          font-size: $FONT_12 !important;
                        }

                        span {
                          font-size: $FONT_12 !important;
                        }

                        a {
                          font-size: $FONT_12 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .bottomButton {
            .necModalTitleSecond {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }

  .howToVote {
    .modal-dialog {
      .modal-content {
        .step-back {
          .title {
            font-size: $FONT_16;
          }

          ul {
            li {
              font-size: $FONT_14;
            }
          }
        }
      }
    }
  }
}

.tooltip {
  &.show {
    opacity: 1;
    transform: translate3d(734.545px, -214.545px, 0px);

    .votingDetail {
      display: flex;
      background-color: rgba(240, 244, 255, 1);
      border-radius: 8px;
      margin-bottom: 4px;

      &:last-child {
        margin: 0;
      }

      .blankConstituency {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: rgba(64, 87, 146, 0.4);
      }

      .authority {
        display: flex;
        flex-direction: column;
        width: calc(100% - 32px);
        padding-left: 4px;

        p {
          font-size: $FONT_12;
          font-family: $NEXAREGULAR;
          text-align: left;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.cName {
            color: $TEXT_LAMBENT_LAGOON;
          }

          &.vNumber {
            color: $TEXT_ORANGE;
          }
        }
      }
    }
  }

  .tooltip-inner {
    background-color: $BG_WHITE;
    padding: 8px 16px 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
      0px 20px 32px rgba(96, 97, 112, 0.24);

    .labelTitle {
      font-family: $NEXAREGULAR;
      color: $TEXT_DARKER_GRAY;
      font-size: $FONT_12;
      text-align: left;
    }
  }
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $BG_WHITE;
}

.votingTooltip {
  width: 187px;
  padding: 0;

  .tooltip-inner {
    padding: 7px 8px;
  }

  .tooltip-arrow {
    transform: translate3d(87.2727px, 7px, 0px) !important;
  }
}

.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $BG_WHITE;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
