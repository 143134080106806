@import "../../../assets/scss/variable.scss";

.confirmModal {
  margin: 0 auto;

  &.delete-data-modal {
    .modal-dialog .modal-content {
      .subTitle {
        color: $TEXT_RED;
        font-size: $FONT_14;
      }

      .modal-footer {
        .yesRemove {
          color: $TEXT_WHITE !important;
        }
      }
    }
  }

  &.confirm-remove {
    .modal-dialog .modal-content {
      i {
        color: $TEXT_RED !important;
      }
    }
  }

  &.delete {
    .modal-dialog .modal-content i {
      color: $BG_RED !important;
    }
  }

  .modal-dialog {
    width: 95%;
    max-width: 360px;

    .modal-content {
      border: 0;
      border-radius: 8px;
      text-align: center;
      padding: 24px;
      overflow: hidden;
      box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      -webkit-box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      -moz-box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      -ms-box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      -o-box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);

      .title {
        margin: 24px 0;
        text-align: center;
        font-family: $NEXAREGULAR;
        font-size: $FONT_14;
        color: $TEXT_CARBON;
      }

      .titleWith {
        margin: 0;
        margin-top: 24px;
        text-align: center;
        font-family: $NEXAREGULAR;
        font-size: $FONT_14;
        color: $TEXT_CARBON;
      }

      .subTitle {
        margin-top: 8px;
        margin-bottom: 24px;
        text-align: center;
        font-family: $NEXAREGULAR;
        font-size: $FONT_14;
        color: $TEXT_CARBON;

      }

      i {
        margin: 0 auto;
        font-size: 48px;
      }


      .modal-footer {
        padding: 0;
        border: 0;
        border-radius: 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: inherit;

        .custombtn {
          width: 48%;
          padding: 0 !important;
          margin: 0;
          font-size: $FONT_16 !important;
          color: $TEXT_CARBON;

          &.yesRemove {
            background-color: $TEXT_RED !important;
            color: $TEXT_WHITE !important;
          }

          &.yesDelete {
            background-color: $BG_RED !important;
            color: $TEXT_WHITE !important;
          }

          &:first-child {
            background-color: $BG_YELLOW;

          }

          &:last-child {
            background-color: transparent !important;
            color: $TEXT_DARKER_GRAY !important;
            border: 2px solid #d0d0d0 !important;
          }

          &.noCancel {
            border: none !important;
          }
        }
      }
    }
  }
}