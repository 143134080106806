@import "../../../assets/scss/variable.scss";

.labelTitle {
  margin: 0;
  font-size: $FONT_24;
  color: $TEXT_LAMBENT_LAGOON;
  text-align: left;

  label {
    color: $TEXT_GRAY;
    margin: 0px;
    font-family: $NEXAREGULAR;
    font-weight: 400;
    font-size: $FONT_14;
    color: $TEXT_RED;

  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .labelTitle {
    font-size: $FONT_20;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .labelTitle {
    font-size: $FONT_22;
  }
}