@import "./assets/scss/variable.scss";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  background-color: $BG_LIGHT_BLUE  !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "nexaregular", sans-serif !important;
  font-size: $FONT_14;
}

body::before {
  position: fixed;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 329px;
  background-color: $BG_LAMBENT_LAGOON;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  -ms-clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  -o-clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  -moz-clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
}

@font-face {
  font-family: "nexaregular";
  src: url("./assets/fonts/nexaregular-webfont.woff2") format("woff2"),
    url("./assets/fonts/nexaregular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "nexa_boldregular";
  src: url("./assets/fonts/nexa_bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/nexa_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'youngScot';
  src:  url('./assets/fonts/youngScot.eot?kgj4n');
  src:  url('./assets/fonts/youngScot.eot?kgj4n#iefix') format('embedded-opentype'),
    url('./assets/fonts/youngScot.ttf?kgj4n') format('truetype'),
    url('./assets/fonts/youngScot.woff?kgj4n') format('woff'),
    url('./assets/fonts/youngScot.svg?kgj4n#youngScot') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'youngScot' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-upload:before {
  content: "\e900";
}
.icon-at-sign:before {
  content: "\e901";
}
.icon-location1:before {
  content: "\e902";
}
.icon-filter:before {
  content: "\e903";
}
.icon-menu:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e906";
}
.icon-alert-circle:before {
  content: "\e907";
}
.icon-alert-octagon:before {
  content: "\e908";
}
.icon-arrow-down:before {
  content: "\e909";
}
.icon-arrow-up:before {
  content: "\e90a";
}
.icon-ballet-ticket:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-check-circle:before {
  content: "\e90d";
}
.icon-check:before {
  content: "\e90e";
}
.icon-chevron-down:before {
  content: "\e90f";
}
.icon-clock:before {
  content: "\e910";
}
.icon-cross-fill:before {
  content: "\e911";
}
.icon-cross:before {
  content: "\e912";
}
.icon-download:before {
  content: "\e913";
}
.icon-edit:before {
  content: "\e914";
}
.icon-eye-off:before {
  content: "\e915";
}
.icon-eye:before {
  content: "\e916";
}
.icon-fire-time:before {
  content: "\e917";
}
.icon-hand:before {
  content: "\e918";
}
.icon-hash:before {
  content: "\e919";
}
.icon-list:before {
  content: "\e91a";
}
.icon-location:before {
  content: "\e91b";
}
.icon-mail:before {
  content: "\e91c";
}
.icon-post:before {
  content: "\e91d";
}
.icon-search:before {
  content: "\e91e";
}
.icon-sun:before {
  content: "\e91f";
}
.icon-trash:before {
  content: "\e920";
}
.icon-warning:before {
  content: "\e921";
}
.icon-crown:before {
  content: "\e922";
}
.icon-right-arrow:before {
  content: "\e923";
}
@media (max-width: 767px) {
  body::before {
    display: none;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  body::before {
    height: 130px;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  body::before {
    height: 170px;
  }
}

.quill .ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -2.5em;
  margin-right: 0px;
}
.quill .ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 2.5em;
}
.quill .ql-editor ol li:not(.ql-direction-rtl),
.quill .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 2.5em;
}