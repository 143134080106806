@import "../../../assets/scss/variable.scss";

.navbar {
  background-color: $BG_LAMBENT_LAGOON;
  position: fixed !important;
  width: 100%;
  left: 0;
  top: 0;
  padding: 10px 20px;
  z-index: 99;

  .navbar-brand {
    padding: 0;
    margin: 0 100px 0 0;
  }

  .navbar-nav {
    .nav-link {
      font-family: $NEXABOLD;
      margin: 0;
      font-size: $FONT_16;
      color: $TEXT_WHITE !important;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding: 12px 32px !important;

      &:last-child {
        margin-right: 0;
      }

      &.activeLink {
        background: #e9edf7;
        border-radius: 8px;
        color: $BG_LAMBENT_LAGOON !important;
      }
    }
  }

  .userDetails {
    margin-right: 8px;

    .userName {
      margin: 0;
      font-family: $NEXABOLD;
      font-size: $FONT_14;
      color: $TEXT_WHITE;
      text-align: left;
    }

    .userEmail {
      margin: 0;
      font-family: $NEXAREGULAR;
      font-size: $FONT_14;
      color: $TEXT_WHITE;
      text-align: left;
    }
  }

  .userButton {
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: transparent;
      outline: none;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
    }

    .userButtonImage {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .userAvtar {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $BG_WHITE;

        p {
          margin: 0;
          font-family: $NEXABOLD;
          font-size: $FONT_20;
          color: $TEXT_LAMBENT_LAGOON;
          text-transform: uppercase;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          object-fit: cover;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    background-color: $BG_LAMBENT_LAGOON;
    position: fixed !important;
    width: 100%;
    left: 0;
    top: 0;
    padding: 6px 16px;
    z-index: 99;

    .navbar-brand {
      margin: 0;
    }

    .navbar-toggler {
      padding: 0;
      border: 0;
      border-radius: 0;
      margin-right: 55px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .navbar-nav {
      .nav-link {
        font-size: $FONT_14;
        margin-right: 10px;
        padding: 10px !important;
      }
    }

    .userDetails {
      display: none;
    }

    .userButton {
      position: fixed;
      right: 16px;
      top: 12px;

      .userButtonImage {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .userAvtar {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          p {
            font-size: $FONT_16;
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .navbar {
    .navbar-brand {
      margin-right: 36px;
    }

    .navbar-nav {
      .nav-link {
        padding: 12px 12px !important;
      }
    }

    .userDetails {
      display: none;
    }

    .userButton {
      padding: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }

      .userButtonImage {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .userAvtar {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $BG_WHITE;

          p {
            margin: 0;
            font-family: $NEXABOLD;
            font-size: $FONT_20;
            color: $TEXT_LAMBENT_LAGOON;
            text-transform: uppercase;
          }

          img {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1279px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar {
    .navbar-brand {
    }

    .navbar-nav {
      .nav-link {
        padding: 12px 20px !important;
      }
    }

    .userDetails {
      margin-right: 8px;

      .userName {
        margin: 0;
        font-family: $NEXABOLD;
        font-size: $FONT_14;
        color: $TEXT_WHITE;
        text-align: left;
      }

      .userEmail {
        margin: 0;
        font-family: $NEXAREGULAR;
        font-size: $FONT_14;
        color: $TEXT_WHITE;
        text-align: left;
      }
    }

    .userButton {
      padding: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }

      .userButtonImage {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .userAvtar {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $BG_WHITE;

          p {
            margin: 0;
            font-family: $NEXABOLD;
            font-size: $FONT_20;
            color: $TEXT_LAMBENT_LAGOON;
            text-transform: uppercase;
          }

          img {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
