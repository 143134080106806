@import '../../../assets/scss/variable.scss';

.toast {
  position: fixed;
  top: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1000000;
  text-align: center;
  flex-basis: inherit !important;
  margin: 0 auto !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 20px 0 0 0;
  display: none;
  width: inherit !important;

  &.success-txt,
  &.error-txt,
  &.warning-txt {
    .tostcenter {
      p {
        &::before {
          font-family: 'youngScot' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          font-size: $FONT_24;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 10px;
        }
      }
    }
  }

  &.success-txt {
    .tostcenter {
      p {
        &::before {
          content: '\e90d';
          color: $TEXT_SUCCESS;
        }
      }
    }
  }

  &.error-txt {
    .tostcenter {
      p {
        &::before {
          content: '\e908';
          color: $TEXT_RED;
        }
      }
    }
  }

  &.warning-txt {
    .tostcenter {
      &::before {
        content: '\e907';
        color: $TEXT_YELLOW;
      }
    }
  }

  .tostcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 240px;
    border-radius: 8px;
    padding: 24px 20px 21px 20px;
    text-align: center;
    margin: 0 auto;
    background-color: $BG_WHITE;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
    position: relative;
    overflow: hidden;

    p {
      font-weight: normal;
      text-align: left;
      line-height: 16px;
      margin: 0px;
      font-family: $NEXAREGULAR;
      font-size: $FONT_14;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &.show {
    display: block;
  }

  &.success-txt {
    .tostcenter {
      p {
        color: $TEXT_CARBON;
      }
    }
  }

  &.error-txt {
    .tostcenter {
      p {
        color: $TEXT_CARBON;
      }
    }
  }

  &.warning-txt {
    .tostcenter {
      p {
        color: $TEXT_CARBON;
      }
    }
  }
}
