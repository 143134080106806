//BG COLORS
$BG_WHITE: #ffffff;
$BG_GRAY: #bbbbbb;
$BG_CARBON: #333333;
$BG_VIVIDCERISE: #e01984;
$BG_LAMBENT_LAGOON: #405792;
$BG_CULTURED: #f8f8f8;
$BG_LIGHT_BLUE: #f0f4ff;
$BG_DARKER_GRAY: #7b8591;
$BG_RED: #ff5858;
$BG_YELLOW: #fbcf3f;
$BG_YELLOW_BTN: #FCD815;
$BG_SUCCESS: #32d74c;
$BG_TAB: #e9edf7;
$BG_BRIGHT_GRAY: #E5E9EC;
$BG_PROFILE_COLOR: #DFE2E4;
$BG_ICON_EDIT: #0DACF51A;
$BG_ICON_TRASH: #FF46001A;

// Text Colors
$TEXT_WHITE: #ffffff;
$TEXT_GRAY: #bbbbbb;
$TEXT_CARBON: #333333;
$TEXT_VIVIDCERISE: #e01984;
$TEXT_LAMBENT_LAGOON: #405792;
$TEXT_DARK_BLUE: #3f58a4;
$TEXT_CULTURED: #f8f8f8;
$TEXT_LIGHT_BLUE: #f0f4ff;
$TEXT_DARKER_GRAY: #7b8591;
$TEXT_RED: #ff5858;
$TEXT_YELLOW: #fbcf3f;
$TEXT_BOULDER: #797979;
$TEXT_SUCCESS: #32d74c;
$TEXT_PINK: #e11683;
$ICONCOLOR: #b3bcd3;
$TEXT_DARK_GRAY: #a199b8;
$BORDER: #e9e8ed;
$INPUT_BORDER: #e9edf7;
$BUTTONBORDER: #b3bcd3;
$PHILIPPINE_GRAY: #929292;
$LINK: #0dacf5;
$TEXT_GREEN: #79c196;
$LIGHT_GREEN: #d7ecdf;
$BORDER_GREEN: #bbdfc9;
$TEXT_ORANGE: #f59f4a;
$TEXT_LIGHTDARK_BLACK: #292c31;
$HOVER_BORDER: #aeb8cf;
$SHADOW_BLUE: #838AA3;
$TEXT_BOY_RED: #494B68;
$RUSSIAN_VIOLET :#221747;
$TEXT_DARK_SILVER: #707070;
$TEXT_TRASH: #FF4600;

// Font Size
$FONT_10: 10px;
$FONT_11: 11px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_30: 30px;
$FONT_32: 32px;
$FONT_35: 35px;
$FONT_36: 36px;
$FONT_52: 52px;

//Font-family
$NEXAREGULAR: "nexaregular";
$NEXABOLD: "nexa_boldregular";
$VERDANA: "Verdana";