@import "../../../assets/scss/variable.scss";

.textField {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 8px;


  &.notRequired {
    label::after {
      content: "";
    }
  }

  input {
    position: relative;
    z-index: 9;
    background: transparent;
    border: 1px solid #aaa;
    padding: 12px 16px;
  }

  // label {
  //   position: absolute;
  //   left: 16px;
  //   top: 12px;
  //   color: $TEXT_GRAY;
  //   margin: 0px;
  //   z-index: 9;
  //   font-family: $NEXAREGULAR;
  //   font-weight: 400;
  //   font-size: $FONT_14;
  //   line-height: 24px;
  // }

  // label::after {
  //   content: "*";
  //   color: red;
  // }

  // input[required]:valid+label {
  //   display: none !important;
  // }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: $BG_WHITE;
    cursor: not-allowed;
  }


  .form-control {
    background: $BG_WHITE;
    font-family: $NEXABOLD;
    color: $TEXT_CARBON;
    font-size: $FONT_14;
    padding: 6px 16px;
    padding-right: 40px;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $BG_GRAY;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:disabled {
      background: #f6f6f6;
      border: 1px solid #eee;
      color: $TEXT_GRAY;
      cursor: not-allowed;
    }

    &.error {
      border: 1px solid $BG_RED;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:focus-visible {
      text-shadow: none;
    }

    &::placeholder {
      color: $TEXT_GRAY;
      opacity: 1;
      font-family: $NEXAREGULAR;
    }

    &:-ms-input-placeholder {
      color: $TEXT_GRAY;
      font-family: $NEXAREGULAR;
    }

    &::-ms-input-placeholder {
      color: $TEXT_GRAY;
      font-family: $NEXAREGULAR;
    }
  }

  .iconSection {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }

  .icon-css {
    position: relative;
    font-size: $FONT_18;
    cursor: pointer;

    &.icon-eye-off {
      color: $ICONCOLOR;
    }

    &.icon-eye-on {
      color: $TEXT_CARBON;
    }
  }

  .errorMsg {
    position: relative;
    width: 100%;
    left: 0;
    display: block;
    font-size: $FONT_12;
    text-align: left;
    padding: 4px 0;
    margin: 0;
    color: $TEXT_WHITE;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    font-family: $NEXAREGULAR;
  }

  .errorMsg.show {
    max-height: 130px;
    transition: max-height 0.3s ease-in;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    color: $TEXT_RED;

    .red,
    .green {
      display: flex;
      padding: 2px 0;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }

    .red {
      span {
        color: $TEXT_RED;
      }
    }

    .green {
      span {
        color: $TEXT_SUCCESS;
      }
    }
  }
}